import Api from '@/services/Index';

// Reservation
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/' + id);
}
const updateReservationStatus = async (id,data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/housing/reservations/'+id + '/status',data );
}

const update = async (id,data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/housing/reservations/'+id ,data );
}
const exportExcel = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/excel-export',config);
}

const createCard = (id, side = "") => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/cards/create/'+id + `?side=${side}`, {responseType: 'arraybuffer'});
}
const printCard = (id, side = "") => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/cards/print/' + id + `?side=${side}`, {responseType: 'arraybuffer'});
}
export default {
    getAll,
    get,
    updateReservationStatus,
    update,
    exportExcel,
    createCard,
    printCard
}
