<template>
    <div>
        <loading v-show="pageLoading"/>
        <div v-show="!pageLoading">
            <div>
                <ValidationObserver ref="reservationFormModalValidate">
                    <b-row>
                        <b-col cols="6">
                            <ValidationProvider name="name" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('name')">
                                    <b-form-input v-model="formData.name" readonly :class="errors[0] ? 'is-invalid':''">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                            <ValidationProvider name="surname" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('last_name')">
                                    <b-form-input v-model="formData.surname" readonly
                                                  :class="errors[0] ? 'is-invalid':''">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>


                        <b-col cols="6">
                            <ValidationProvider name="check_in_date" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('check_in_date')">
                                    <select-date v-model="formData.check_in_date"
                                                  :class="errors[0] ? 'is-invalid':''">
                                    </select-date>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                            <ValidationProvider name="check_out_date" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('check_out_date')">
                                    <select-date v-model="formData.check_out_date"
                                                  :class="errors[0] ? 'is-invalid':''">
                                    </select-date>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group label="Room / Bed">
                                <div class="label-as-input">{{formData.room_name}} - {{formData.room_type_name}} / {{formData.bed_name}} - {{formData.bed_type_name}}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <ValidationProvider name="type" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('type')">
                                    <b-form-input v-model="formData.type" readonly :class="errors[0] ? 'is-invalid':''">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                            <ValidationProvider name="total_days" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('days')">
                                    <b-form-input v-model="formData.total_days" readonly :class="errors[0] ? 'is-invalid':''">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                            <ValidationProvider name="fee" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('monthly_fee')">
                                    <b-form-input v-model="fee" readonly :class="errors[0] ? 'is-invalid':''">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                            <ValidationProvider name="discount_amount" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('discount_amount')">
                                    <b-form-input type="number" v-model="formData.discount_amount" :class="errors[0] ? 'is-invalid':''">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                         <b-col>
                            <ValidationProvider name="total_amount" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('total_amount')">
                                    <b-form-input v-model="totalAmount" readonly
                                                  :class="errors[0] ? 'is-invalid':''">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox
                                :code="'housing_reservation_statuses'"
                                v-model="formData.status"
                            ></parameter-selectbox>
                        </b-form-group>
                        </b-col>

                        <b-col cols="6" >
                        <b-form-group :label="$t('file')" v-if="formData.file">
                            <b-input-group>
                                <b-form-input
                                    :disabled="true"
                                    v-model="formData.file.file_name"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="primary" @click="downloadFile" v-b-tooltip.hover title="Download Document">
                                        <i class="ri-arrow-down-line"></i>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <div class="width-100">
                    <b-button @click="save()"  class="btn-block" size="lg">
                        {{ $t('save') }}
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Component


// Services
import HousingReservationsService from '@/services/HousingReservationsService'

// Pages
import Loading from '@/components/elements/Loading'


// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import StudentProgramService from "@/services/StudentProgramService";
import {EventBus} from "@/main";
import moment from 'moment';



export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        Loading,
    },
    props: {
        reservationData: {
            type: Object,
            required: true
        },

    },
    data() {
        return {
            pageLoading: false,
            formData: this.reservationData,
        }
    },
    computed:{
      fee(){
          return this.currencyFormat(this.formData.fee?this.formData.fee:0)
      },
        totalAmount(){
            return this.currencyFormat(this.formData.total_amount?this.formData.total_amount:0)
        }
    },
    methods: {
        downloadFile(){
            StudentProgramService.downloadFile(this.formData.file.uuid)
                .then(response => {
                    if (response.headers['content-type'] === 'application/pdf') {
                        let fileName = this.formData.file.file_name
                        EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                    } else {
                        this._downloadFile(response,this.formData.file.name)
                    }
                }).catch(err => {
                this.showErrors(err)
            })

        },
        save() {
            this.formData.check_in_date=moment(this.formData.check_in_date).format('MM/DD/YYYY')
            this.formData.check_out_date=moment(this.formData.check_out_date).format('MM/DD/YYYY')
            HousingReservationsService.update(this.reservationData.id, this.formData)
                .then(res=>{
                    this.$toast.success(this.$t("api." + res.data.message))
                    this.$emit('successUpdate',true)
            }).catch(err=>{
                this.showErrors(err)
            })

        }
    },


}
</script>
